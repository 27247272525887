<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                菜单编码：
                            </td>
                            <td>
                                <el-input v-model="searchItem.menuCode" size="small" placeholder="请输入菜单编码"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                菜单名称：
                            </td>
                            <td>
                                <el-input v-model="searchItem.menuName" size="small" placeholder="请输入菜单名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                父级菜单：
                            </td>
                            <td>
                                <el-select v-model="searchItem.parentId" style='width: 100%' size="small"
                                           placeholder="请选择父级菜单">
                                    <el-option
                                            v-for="item in parentList"
                                            :key="item.id"
                                            :label="item.menuName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 300px ;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus"
                                           size="small" @click="addFunction">
                                    增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="menuCode" width="200" label="菜单编码" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="menuName" width="200" label="菜单名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="menuUrl" width="200" label="菜单地址" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="menuSort" width="100" label="排序" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="menuSort" width="100" label="是否隐藏" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                   <span v-if="scope.row.isHidden == 1" style="color:red;">是</span>
                                   <span v-else style="color:green;">否</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="parentName" width="200" label="父级菜单"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="iconPath" width="200" label="图片配置" align="center"></el-table-column>
                            <el-table-column prop="menuPermissionStr" label="操作按钮" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="number" width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑"
                                           @click="editFunction(scope.row.id)"></i>
                                        <i class="iconfont iconshanchu" title="删除"
                                           @click="deleteFunction(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    :close-on-click-modal="false"
                    :show-close="false"
                    width="30%"
                    center>
                <el-form :model="functionForm" :rules="functionRules" ref="functionForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-form-item size="small" label="菜单编码" prop="menuCode">
                        <el-input size="small" :disabled="isDisabled" v-model="functionForm.menuCode"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="菜单名称" prop="menuName">
                        <el-input size="small" v-model="functionForm.menuName"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="父级菜单" prop="parentId">
                        <el-select v-model="functionForm.parentId" style='width: 100%' size="small"
                                   placeholder="请选择父级菜单">
                            <el-option
                                    v-for="item in parentList"
                                    :key="item.id"
                                    :label="item.menuName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item size="small" label="菜单地址" prop="menuUrl">
                        <el-input size="small" v-model="functionForm.menuUrl"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="菜单排序" prop="menuSort">
                        <el-input size="small" type="tel"
                                  onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                  v-model.number="functionForm.menuSort"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="图标配置" prop="iconPath">
                        <el-input size="small" v-model="functionForm.iconPath"></el-input>
                    </el-form-item>
                    <el-form-item label='是否隐藏' prop="isPageShow">
                        <el-switch
                                v-model="functionForm.isPageShow"
                                active-color="#ff4949"
                                inactive-color="#cccccc">
                        </el-switch>
                    </el-form-item>
                    <div class="addNum">
                        <div class="input" v-for="(item,i) in operList" :key="i">
                            <div class="input-style">
                                <div class="text-show">
                                    操作编码:
                                </div>
                                <div class="input-text">
                                    <el-input v-model="item.menuPerCode" placeholder="请输入操作编码"
                                              :disabled="item.isDisabled" size="small"></el-input>
                                </div>
                                <div class="text-show">
                                    操作名称:
                                </div>
                                <div class="input-text">
                                    <el-input v-model="item.menuPerName" placeholder="请输入操作名称"
                                              size="small"></el-input>
                                </div>
                                <div class="button-style">
                                    <el-button type="danger" icon="el-icon-delete" size="small" @click="deleteBox(i)">删除
                                    </el-button>
                                </div>
                            </div>
                        </div>
                        <div class="button">
                            <el-button type="success" icon="el-icon-ok" size="small" @click="addBox">新增</el-button>
                        </div>
                    </div>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('functionForm')">取 消</el-button>
                    <el-button type="primary" @click="submit('functionForm')" :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [16, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                isDisabled: false,
                isLoad: false,
                operList: [
                    {menuPerCode: 'add', menuPerName: '新增', isDisabled: false},
                    {menuPerCode: 'delete', menuPerName: '删除', isDisabled: false},
                    {menuPerCode: 'update', menuPerName: '修改', isDisabled: false},
                    {menuPerCode: 'query', menuPerName: '查询', isDisabled: false},
                ],
                option: {
                    pageSize: 16,
                    pageIndex: 11,
                },
                searchItem: {
                    menuCode: '',
                    menuName: '',
                    pageIndex: 1,
                    pageSize: 16,
                },
                totalPage: 10,
                type: 0,
                editDialogVisible: false,
                lineHeight: '',
                editTitle: '',
                parentList: [],
                currentPage: 1,
                functionForm: {
                    id: null,
                    menuCode: '',
                    menuName: '',
                    menuUrl: '',
                    menuSort: '',
                    parentId: '',
                    iconPath: '',
                    isHidden: 1,
                    isPageShow: false,
                    menuPermissionStr: ''
                },
                functionRules: {
                    menuCode: [
                        {required: true, message: '请输入菜单编码', trigger: 'blur'}
                    ],
                    menuName: [
                        {required: true, message: '请输入菜单名称', trigger: 'blur'},
                    ],
                    menuSort: [
                        {required: true, message: '请输入菜单排序', trigger: 'blur'}
                    ],
                }
            };
        },
        methods: {
            addBox() {
                this.operList.push({menuPerCode: '', menuPerName: '', isDisabled: false})
            },
            deleteBox(index) {
                this.operList.splice(index, 1)
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.currentPage = option
                this.searchItem.pageIndex = option;
                this.$api.getMenuList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.getMenuList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    menuCode: '',
                    menuName: '',
                    pageIndex: 1,
                    pageSize: 16
                }
                this.pageChange(1);
            },
            getParentList() {
                this.$api.getParentList().then(res => {
                    if (res.code == 200) {
                        this.parentList = res.data;
                    }
                })
            },
            searchBtn() {
                this.pageChange(1);
            },
            addFunction() {
                this.editDialogVisible = true;
                this.isDisabled = false;
                this.isLoad = false;
                this.editTitle = '新增菜单管理'
                this.getParentList();
                this.type = 0;
                this.functionForm = {
                    id: null,
                    menuCode: null,
                    menuName: null,
                    menuUrl: null,
                    menuSort: null,
                    parentId: null,
                    iconPath: null,
                    isPageShow: false,
                    isHidden: 1,
                    menuPermissionStr: ''
                }
                this.operList = [
                    {menuPerCode: 'add', menuPerName: '新增', isDisabled: false},
                    {menuPerCode: 'delete', menuPerName: '删除', isDisabled: false},
                    {menuPerCode: 'update', menuPerName: '修改', isDisabled: false},
                    {menuPerCode: 'query', menuPerName: '查询', isDisabled: false},
                ]
            },
            editFunction(id) {
                this.editTitle = '编辑菜单管理'
                this.type = 1
                this.operList = [];
                this.isDisabled = true;
                this.isLoad = false;
                this.$api.findMenuById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.editDialogVisible = true;
                        this.functionForm = {
                            id: res.data.id,
                            menuCode: res.data.menuCode,
                            menuName: res.data.menuName,
                            menuUrl: res.data.menuUrl,
                            menuSort: res.data.menuSort,
                            parentId: res.data.parentId,
                            isHidden: res.data.isHidden,
                            isPageShow: res.data.isHidden == 0 ? false : true,
                            iconPath: res.data.iconPath,
                        }
                        for (var item of res.data.menuPermissionList) {
                            var operItem = {};
                            operItem.menuPerCode = item.menuPerCode;
                            operItem.menuPerName = item.menuPerName;
                            operItem.isDisabled = true;
                            this.operList.push(operItem);
                        }
                    }
                })
            },
            deleteFunction(id) {
                this.$confirm('你确定要删除菜单信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteFunction({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange(1)
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            submit(formName) {
                for (var i = 0; i < this.operList.length; i++) {
                    for (var j = i + 1; j < this.operList.length; j++) {
                        if (this.operList[i].menuPerCode != '' && this.operList[j].menuPerCode != '') {
                            if (this.operList[i].menuPerCode == this.operList[j].menuPerCode) {
                                this.$message.error('菜单属性第' + (i + 1) + '行和第' + (j + 1) + '行存在操作编码重复，请仔细检查');
                                this.isLoad = false
                                return;
                            }
                        }
                        if (this.operList[i].menuPerName != '' && this.operList[j].menuPerName != '') {
                            if (this.operList[i].menuPerName == this.operList[j].menuPerName) {
                                this.$message.error('菜单属性第' + (i + 1) + '行和第' + (j + 1) + '行存在操作名称重复，请仔细检查');
                                this.isLoad = false
                                return;
                            }
                        }
                    }
                }
                var operListStr = '';
                for (var item of this.operList) {
                    if (operListStr == '') {
                        operListStr = item.menuPerCode + ',' + item.menuPerName;
                    } else {
                        operListStr = operListStr + '|' + item.menuPerCode + ',' + item.menuPerName;
                    }
                }
                this.functionForm.menuPermissionStr = operListStr;
                if (this.functionForm.isPageShow) {
                    this.functionForm.isHidden = 1;
                } else {
                    this.functionForm.isHidden = 0;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.type == 0) {
                            this.$api.addMenu(this.functionForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange(1);
                                    this.editDialogVisible = false;
                                    this.isLoad = false
                                    this.$message.success(res.message)
                                } else {
                                    this.isLoad = false
                                    this.$message.error(res.message)
                                }
                            })
                        } else {
                            this.$api.updateMenu(this.functionForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange(this.currentPage);
                                    this.editDialogVisible = false;
                                    this.isLoad = false
                                    this.$message.success(res.message)
                                } else {
                                    this.isLoad = false
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    } else {
                        this.isLoad = false
                        return false;
                    }
                });
            },
        },
        created() {
            this.getParentList()
            this.pageChange(1)
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 20px;
        color: red;
    }

    .input-style {
        margin: 10px;
        display: flex;
    }

    .text-show {
        width: 80px;
        margin: 8px 5px;
    }

    .input-text {
        width: 31%;
    }

    .button {
        text-align: center;
    }

    .button-style {
        margin-left: 10px;
        text-align: center;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 6%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
    }
</style>